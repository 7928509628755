input[type="text"],
input[type="password"] {
  &.form-control {
    border-radius: 4px;
    border: 2px solid #9EA4BC;
    display: flex;
    height: 64px;

    &.icon {
      padding-left: 55px;

      + svg {
        color: #9EA4BC;
        position: absolute;
        top: calc(24px + .5rem);
        height: 64px;
        width: 57px;
        padding: 0 16px;
      }
    }
  }
}

.form-group {
  position: relative;

  label {
    font-weight: 600;
    color: #1D2131;
  }

  .form-check-label {
    font-weight: 600;
  }
}

.form-check-label {
  font-weight: 500;

  a {
    color: #545DA9;
    font-weight: 700;
  }
}
