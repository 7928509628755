.btn {
  border-width: 3px;
  font-weight: bold;
  font-size: 18px;
  margin: 0 .5rem;

  &-outline {
    &-primary {
      color: #7ECCF5;
      border-color: #7ECCF5;

      &:hover {
        background-color: #7ECCF5;
        border-color: #7ECCF5;
      }
    }
  }

  &-primary {
    border-color: #7ECCF5;
    background-color: #7ECCF5;

    &:hover {
      border-color: darken(#7ECCF5, 10%);
      background-color: darken(#7ECCF5, 10%);
    }
  }

  &-secondary {
    // #545DA9
  }

  &-outline {
    &-secondary {
      color: #545DA9;
      border-color: #545DA9;

      &:hover {
        border-color: #545DA9;
        background-color: #545DA9;
      }
    }
  }
}
